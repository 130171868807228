<template>
	<!-- v-if="isShow" -->
	<div>
		<Header />
		<Carousel v-model="value" loop :height="480" arrow="never" :dots="pageSetup.bannerList.length>1?'inside':'none'">
			<CarouselItem v-for="(item,index) in pageSetup.bannerList">
				<div class="banner" :style="{'background':'url('+item.imgPath+') center center no-repeat'}">
					<div>
						<p v-if="!item.titleIsPicture" class="bannerTitle">{{item.title}}</p>
						<img v-else src="../../assets/img/homeLogo.png" style="width: 450px;margin-bottom: 20px;" />
						<p class="banner_text">{{item.describe}}</p>
						<button class="buttonFill" v-if="$store.state.user.isLogining" @click="login">{{item.btnText}}</button>
					</div>
				</div>
			</CarouselItem>
		</Carousel>
		<div class="mainContent">


			<ul class="appraisal">
				<li class="numberQuotient" v-if="pageSetup.numberQuotient&&pageSetup.showNumberQuotient==1">
					<p class="fontColor">数商测试</p>
					<p>修炼数商是智能时代的新潮流，快来测试一下你的数商有多高？</p>
					<router-link tag="button" class="buttonBorder" to="/numberQuotient">立即测试</router-link>
				</li>
				<li class="digitization" v-if="pageSetup.quotientEvaluation&&pageSetup.showQuotientEvaluation==1">
					<p class="fontColor">数字化企业商业模式测评</p>
					<p>支持各类客户价值的商业模式，快来看看你的企业符合几点？</p>
					<router-link tag="button" class="buttonBorder" to="/digitalModelEvaluation">立即测评</router-link>
				</li>
			</ul>
			<div v-if="pageSetup.showDataEvaluation==1">
				<p class="moduleTitle">{{pageSetup.dataEvaluationTitle}}</p>
				<ul class="evaluate">
					<li>
						<div class="pictureIdentification">
							<img :src="pageSetup.dataEvaluationContent.imgPath" style="border-right: 2px solid #fff;" />
						</div>
						<div class="evaluateIntroduce">
							<p>{{pageSetup.dataEvaluationContent.title}}</p>
							<ul class="describe">
								<li v-for="(item,index) in pageSetup.dataEvaluationContent.describe" v-if="item.value">
									<Icon type="md-checkbox-outline" size="22" :color="$store.state.user.subjectColor.mainColor" />
									<span>{{item.value}}</span>
								</li>
							</ul>
							<button class="buttonBorder" @click="openPdf(pageSetup.dataEvaluationContent.pdf)">查看报告示例</button>
							<button class="buttonFill" @click="nowFill(14)">立即测评</button>
						</div>
					</li>
				</ul>
			</div>
			<div v-if="pageSetup.showDataSpecialty==1||pageSetup.showDataFast==1">
				<p class="moduleTitle">{{pageSetup.dataValuationTitle}}</p>
				<ul class="dataValuationEntry">
					<li v-if="pageSetup.showDataFast==1&&pageSetup.dataValuationContent.fast.enabled">
						<div class="name"
							:style="{'background':'url('+pageSetup.dataValuationContent.fast.imgPath+') center center no-repeat'}">
							{{pageSetup.dataValuationContent.fast.title}}
						</div>
						<div class="describe">
							<p>{{pageSetup.dataValuationContent.fast.describe}}</p>
							<div>
								<button class="buttonFill" @click="nowFill(15)">立即估值</button>
								<button class="buttonBorder" @click="openPdf(pageSetup.dataValuationContent.fast.pdf)">查看报告示例</button>
							</div>
						</div>
					</li>
					<li v-if="pageSetup.showDataSpecialty==1&&pageSetup.dataValuationContent.specialty.enabled">
						<div class="name"
							:style="{'background':'url('+pageSetup.dataValuationContent.specialty.imgPath+') center center no-repeat'}">
							{{pageSetup.dataValuationContent.specialty.title}}
						</div>
						<div class="describe">
							<p>{{pageSetup.dataValuationContent.specialty.describe}}</p>
							<div>
								<button class="buttonFill" @click="nowFill(13)">立即估值</button>
								<button class="buttonBorder"
									@click="openPdf(pageSetup.dataValuationContent.specialty.pdf)">查看报告示例</button>
							</div>
						</div>
					</li>
				</ul>
			</div>
			<div v-if="pageSetup.showDataRealization==1">
				<p class="moduleTitle">{{pageSetup.dataRealizationTitle}}</p>
				<ul class="dataRealizationType">
					<li v-for="(item,index) in dataRealizationType" :key="index" :class="[slideIndex === item.type?'active':'']"
						@click="selectLiquidationType(item.type)">
						{{item.name}}
					</li>
				</ul>
				<div v-if="records.length==0" class="nothing" style="height: 300px;">
					<div v-if="nothing">
						<img src="../../assets/img/img_nothing.png" alt="暂无信息" style="margin-top: 40px;" />
						<p>暂无信息</p>
					</div>
				</div>

				<div v-else style="min-height: 300px;">
					<ul class="dataMonetizationProducts dataAssetAPI" v-if="slideIndex==7|| slideIndex==4||slideIndex==1">
						<li v-for="(item,index) in records">
							<img :src="item.logo" :style="{'height':slideIndex==7?'49px':'30px'}" />
							<p class="dataProductsName">{{item.name}}</p>
							<p class="dataProductsDescribe">{{item.profile}}</p>
							<button class="buttonBorderTransparency" @click="viewDetails(item.id)">查看详情</button>
						</li>
					</ul>
					<ul class="dataMonetizationProducts dataAssetPledgeFinancing" v-if="slideIndex==5">
						<li v-for="(item,index) in records">
							<img :src="item.logo" alt="产品logo" />
							<p class="dataProductsName">{{item.name}}</p>
							<div class="detailed">
								<p>
									<span>
										<i class="iconfont icon-shangshengjiantou"
											:style="{'color':$store.state.user.subjectColor.mainColor}"></i>
									</span>
									<span>年利率：{{item.rate}}</span>
								</p>
								<p>
									<span>
										<i class="iconfont icon-a-9xinyongqixianedushenpi"
											:style="{'color':$store.state.user.subjectColor.mainColor}"></i>
									</span>
									<span>贷款期限：{{item.term}}</span>
								</p>
								<p>
									<span>
										<i class="iconfont icon-mianfeitixianedu"
											:style="{'color':$store.state.user.subjectColor.mainColor}"></i>
									</span>
									<span>贷款额度：{{item.loan}}</span>
								</p>
							</div>
							<button class="buttonBorderTransparency" @click="viewDetails(item.id)">查看详情</button>
						</li>
					</ul>
					<ul class="dataMonetizationProducts dataAssetPledgeFinancing" v-if="slideIndex==3">
						<li v-for="(item,index) in records">
							<img :src="item.logo" alt="产品logo" />
							<p class="dataProductsName">{{item.name}}</p>
							<div class="detailed">
								<p>
									<span>
										<i class="iconfont icon-shouyi"
											:style="{'color':$store.state.user.subjectColor.mainColor,'fontSize':'18px'}"></i>
									</span>
									<span>客户买入到期收益率：{{item.rate}}</span>
								</p>
								<p>
									<span>
										<i class="iconfont icon-a-9xinyongqixianedushenpi"
											:style="{'color':$store.state.user.subjectColor.mainColor}"></i>
									</span>
									<span>贷款期限：{{item.term}}</span>
								</p>
								<p>
									<span>
										<i class="iconfont icon-shengyuliang"
											:style="{'color':$store.state.user.subjectColor.mainColor}"></i>
									</span>
									<span>剩余期限：{{item.residueTerm}}</span>
								</p>
							</div>
							<button class="buttonBorderTransparency" @click="viewDetails(item.id)">查看详情</button>
						</li>
					</ul>
					<ul class="dataMonetizationProducts dataAssetPledgeFinancing" v-if="slideIndex==2">
						<li v-for="(item,index) in records">
							<img :src="item.logo" alt="产品logo" />
							<p class="dataProductsName">{{item.name}}</p>
							<div class="detailed">
								<p>
									<span>
										<i class="iconfont icon-xiangmujine"
											:style="{'color':$store.state.user.subjectColor.mainColor}"></i>
									</span>
									<span>发行金额：{{item.price}}</span>
								</p>
								<p>
									<span>
										<i class="iconfont icon-a-piaomianxuanzhong"
											:style="{'color':$store.state.user.subjectColor.mainColor}"></i>
									</span>
									<span> 票面利率：{{item.rate}}</span>
								</p>
								<p>
									<span>
										<i class="iconfont icon-yingyeqixian"
											:style="{'color':$store.state.user.subjectColor.mainColor}"></i>
									</span>
									<span>发行期限：{{item.term}}</span>
								</p>
							</div>
							<button class="buttonBorderTransparency" @click="viewDetails(item.id)">查看详情</button>
						</li>
					</ul>
					<ul class="dataMonetizationProducts dataAssetPledgeFinancing" v-if="slideIndex==6">
						<li v-for="(item,index) in records">
							<div class="logo">
								<img :src="item.logo" alt="产品logo" />
							</div>
							<p class="dataProductsName">{{item.name}}</p>
							<div class="detailed">
								<p>
									<span>
										<i class="iconfont icon-xingye"
											:style="{'color':$store.state.user.subjectColor.mainColor,'fontSize':'18px'}"></i>
									</span>
									<span>行业：{{item.industry}}</span>
								</p>
								<p>
									<span>
										<i class="iconfont icon-dizhi"
											:style="{'color':$store.state.user.subjectColor.mainColor,'fontSize':'18px'}"></i>
									</span>
									<span>地址：{{item.address}}</span>
								</p>
							</div>
							<button class="buttonBorderTransparency" @click="viewDetails(item.id)">查看详情</button>
						</li>
					</ul>

				</div>


			</div>
		</div>
		<div
			:class="['cooperativePartner',$store.state.user.subjectColor.mainColor=='#00bfbf'?'partnerGreen':$store.state.user.subjectColor.mainColor=='#f59a23'?'partnerOrange':$store.state.user.subjectColor.mainColor=='#d9001b'?'partnerRed':'partnerBlue']"
			v-if="pageSetup.showPartner==1">
			<div>
				<p>{{pageSetup.partnerTitle}}</p>
				<ul>
					<li v-for="item in randomImages">
						<img :src="item.logo" alt="" />
					</li>
				</ul>
			</div>
		</div>

		<!-- 	<ul style="display: flex;" v-if="newArry.length>0">
			<li v-for="item in newArry" style="margin-right: 20px;width: 100px;">
				<img :src="item.logo" alt="" style="width: 100%;height: 100%;object-fit: contain;" />
			</li>
		</ul> -->

		<SignUp />
		<Footer />

	</div>
</template>

<script>
	import Header from '../../common/header/flow_header.vue'
	import Footer from '../../common/footer.vue'
	import publicMethod from '../publicMethod'
	import SignUp from '@/components/signUp/index.vue'
	import '@/assets/css/animate.css'

	import qs from 'qs'
	import axios from 'axios'

	import {
		Spin
	} from 'iview'

	export default {
		mixins: [publicMethod],
		components: {
			Header,
			Footer,
			SignUp
		},
		data() {
			return {
				nothing: true,
				pageSetup: {
					bannerList: [],
					dataEvaluationContent: {
						imgPath: '',
						title: '',
						padf: '',
						describe: []
					},
					dataValuationContent: {
						imgPath: '',
						title: '',
						padf: '',
						describe: []
					}
				},
				dataRealizationType: [{
					name: '数据资产API',
					type: 7
				}, {
					name: '股权融资',
					type: 6
				}, {
					name: '数据资产质押融资',
					type: 5
				}, {
					name: '数据资产通证',
					type: 4
				}, {
					name: '数据资产债券融资',
					type: 3
				}, {
					name: '数据资产证券化',
					type: 2
				}, {
					name: '数据保险',
					type: 1
				}],
				slideIndex: 1,
				value: 0,
				records: [],
				cooperativePartner: [],
				displayedImages: [],
				randomImages: [],
				newArry: []


			}
		},
		mounted() {
			this.getInfo(1)
			this.queryPartnerList()
			this.getDataProductTypeList()
			
			sessionStorage.removeItem('isHistoryCache')
			sessionStorage.removeItem('netWorth')

			$(document).on('mouseenter', '.cooperativePartner li', function() {
				$(this).addClass('animated flipInX').one('animationend', function() {
					$(this).removeClass('animated flipInX');
					// $(this).removeClass('animated flipInX');
				});
			});

			$(document).on('mouseleave', '.cooperativePartner li', function() {
				$(this).addClass('animated flipInX').one('animationend', function() {
					$(this).removeClass('animated flipInX');
				});
			});



		},
		methods: {
			selectLiquidationType(type) {
				this.slideIndex = type
				this.findDataProduct(1, 4, type)
			},
			getDifferenceArray(arrA, arrB) {
				// 使用 filter() 方法和 some() 方法来判断数组 A 不包含数组 B 中的内容
				var differenceArray = arrB.filter(itemB => !arrA.some(itemA => itemA.logo === itemB.logo));

				return differenceArray;
			},
			getRandomSubarray(arr, size) {
				const shuffled = arr.slice(0);
				let i = arr.length;
				if (i < size) {
					console.log('数组元素个数小于需要随机选取的元素个数。');
				}
				const min = i - size;
				for (let j = min; j > 0; j--) {
					const index = Math.floor(Math.random() * (i--));
					[shuffled[index], shuffled[i]] = [shuffled[i], shuffled[index]];
				}

				return shuffled.slice(min);
			},
			queryPartnerList() { //合作伙伴
				var this_ = this

				this.$post(this.domain.dataRealization + 'cooperativePartner/getPartnerList', qs.stringify({
						channelCode: window.channel
					}))
					.then((res) => {
						this.cooperativePartner = res.data.content
						if (res.data.content.length < 5) {
							this.randomImages = res.data.content
						} else {
							this.animationEffect()
						}
					});
			},
			animationEffect() {
				var this_ = this
				if (this.cooperativePartner.length > 0 && this.cooperativePartner.length > 10) {
					// setInterval  setTimeout
					this.randomImages = this.getRandomSubarray(this.cooperativePartner, 10);
					this.newArry = this.getDifferenceArray(this.randomImages, this.cooperativePartner)

					setInterval(() => {
						var randomImg = this_.randomImages[Math.floor(Math.random() * this_.randomImages.length)]
						var index = this_.randomImages.findIndex(item => item.logo === randomImg.logo);


						$(document).find('.cooperativePartner li').eq(index).addClass('animated fadeOut').one(
							'animationend',
							function() {
								$(this).removeClass('animated fadeOut');
								var randomIndex2 = Math.floor(Math.random() * this_.newArry.length);
								var temp = this_.randomImages[index];
								this_.$set(this_.randomImages, index, this_.newArry[randomIndex2]);
								this_.$set(this_.newArry, randomIndex2, temp);

							});
					}, 5000)
				} else if (this.cooperativePartner.length > 0 && this.cooperativePartner.length <= 10) {
					this_.randomImages = this.cooperativePartner
					// console.log('没有替换替换位置------', this_.randomImages)

					setInterval(() => {
						// 随机生成两个不重复的索引
						var randomIndex1 = Math.floor(Math.random() * this_.randomImages.length);
						var randomIndex2 = Math.floor(Math.random() * this_.randomImages.length);
						while (randomIndex2 === randomIndex1) {
							randomIndex2 = Math.floor(Math.random() * this_.randomImages.length);
						}

						// 交换数组元素位置
						var temp = this_.randomImages[randomIndex1];
						this_.$set(this_.randomImages, randomIndex1, this_.randomImages[randomIndex2]);
						this_.$set(this_.randomImages, randomIndex2, temp);

						$(document).find('.cooperativePartner li').eq(randomIndex2).addClass('animated fadeOut').one(
							'animationend',
							function() {
								$(this).css('animation-duration', '1s !important').removeClass('animated fadeOut')
							});

						// $(document).find('.cooperativePartner li').eq(randomIndex1).addClass('animated fadeOut').one(
						// 	'animationend',
						// 	function() {
						// 		$(this).css('animation-duration', '1s !important').removeClass('animated fadeOut')
						// 	});

						// console.log('小于10替换位置------', this_.randomImages)

					}, 5000)

				}
			},
			nowFill(type) {
				this.$router.push({
					path: type == 13 ? 'dataInfoSpecialty' : type == 14 ? 'basicInformationEvaluation' :
						'dataValuationInfoFast',
					query: {
						type: type
					},
				});
			},
			login() {
				this.$router.push({
					name: 'login'
				})
			},
			getDataProductTypeList() {
				this.$post(this.domain.dataRealization + 'dataProductType/getDataProductTypeList', qs.stringify({}))
					.then((res) => {
						this.dataRealizationType = res.data.content.slice(0, 7)
						this.slideIndex = res.data.content[0].type
						this.findDataProduct(1, 4, res.data.content[0].type)

					});
			},
			findDataProduct(pageNum, pageSize, type) {
				this.records = []
				this.nothing = false
				this.$post(this.domain.dataRealization + 'dataProduct/getDataProductList', qs.stringify({
						pageNum: pageNum,
						pageSize: pageSize,
						type: type,
						status: '3'
					}))
					.then((res) => {
						this.records = res.data.content.records
						if (res.data.content.records.length == 0) this.nothing = true
					});
			},
			viewDetails(id) {
				this.$router.push({
					path: 'dataCashProductDetails',
					query: {
						productId: id
					}
				});
			},


		}

	}
</script>

<style scoped lang="less">
	.appraisal {
		display: flex;
		justify-content: space-between;
		margin-top: 51px;

		li {
			width: 590px;
			height: 172px;
			background: #FFFFFF;
			border: 2px solid #FFFFFF;
			padding: 29px 0 0 31px;
			box-shadow: 2px 2px 13px 0px rgba(6, 76, 156, 0.23);
			box-sizing: border-box;

			p:first-child {
				font-size: 20px;
				font-weight: bold;
				margin-bottom: 7px;
			}

			p:nth-of-type(2) {
				width: 282px;
				font-size: 14px;
				color: #282828;
				line-height: 20px;
				margin-bottom: 17px;
			}

			button {
				width: 110px;
				height: 30px;
			}
		}

		.numberQuotient {
			background: url(../../assets/img/home/numberQuotient.png) center center no-repeat;
			background-size: cover;
		}

		.digitization {
			background: url(../../assets/img/home/digitization.png) center center no-repeat;
			background-size: cover;
		}

	}

	.evaluate {
		display: flex;


		>li {
			display: flex;
			background: #F4F7FA;
			box-shadow: 2px 2px 13px 0px rgba(6, 76, 156, 0.23);
			border: 2px solid #fff;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.pictureIdentification {
			width: 738px;
		}

		.evaluateIntroduce {
			width: calc(100% - 738px);
			padding: 30px 50px 0;
			box-sizing: border-box;

			>p {
				font-size: 20px;
				font-weight: bold;
				margin-bottom: 30px;
				color: #282828;
			}

			button {
				width: 320px;
				height: 36px;
				margin-bottom: 16px;
			}

			.describe {

				li {
					margin-bottom: 27px;
					line-height: 20px;

					span {
						width: 90%;
						font-size: 14px;
						color: #282828;
						margin-left: 13px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
				}
			}
		}
	}

	.dataValuationEntry {
		display: flex;
		justify-content: space-between;

		li {
			width: 590px;
			border: 2px solid #FFFFFF;
			box-shadow: 2px 2px 13px 0px rgba(6, 76, 156, 0.23);

			.name {
				width: 100%;
				height: 130px;
				line-height: 130px;
				padding: 0 30px;
				font-size: 20px;
				font-weight: bold;
				color: #282828;
				background-size: cover !important;
				border-bottom: 2px solid #FFFFFF;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}

			.describe {
				background: #F4F7FA;
				padding: 20px 28px 30px 28px;

				p {
					line-height: 27px;
					font-size: 14px;
					color: #282828;
					margin-bottom: 20px;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
				}

				>div {

					button {
						width: 255px;
						height: 36px;
					}

					button:first-child {
						margin-right: 20px;
					}
				}
			}

		}
	}

	.dataRealizationType {
		display: flex;
		justify-content: space-between;

		li {
			padding-bottom: 10px;
			font-weight: bold;
			font-size: 20px;
			color: #282828;
			cursor: pointer;
		}

		li.active {
			color: var(--mainColor);
			border-bottom: 2px solid var(--mainColor);
		}
	}

	.dataMonetizationProducts {
		margin-top: 20px;
		display: flex;

		li {
			width: 285px;
			background: #F4F7FA;
			border: 2px solid #FFFFFF;
			box-shadow: 2px 2px 13px 0px rgba(6, 76, 156, 0.23);
			margin-right: 20px;
			padding: 30px 0;

			button {
				width: 110px;
				height: 30px;
				margin: 20px auto 0;
				display: block;
			}

			.dataProductsName {
				width: 87%;
				font-size: 20px;
				font-weight: bold;
				color: #282828;
				text-align: center;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				margin: 0 auto 15px;
			}

			.dataProductsDescribe {
				width: 87%;
				min-height: 72px;
				font-size: 14px;
				color: #282828;
				line-height: 24px;
				margin: 0 auto;
				text-align: justify;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 3;
			}

			.companyName {
				margin-bottom: 15px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}

			.detailed {
				width: 83%;
				margin: 0 auto;

				p {
					margin-bottom: 15px;
				}

				p:last-child {
					margin-bottom: 0;
				}

				.iconfont {
					font-size: 23px;
				}

				span:first-child {
					width: 25px;
					text-align: center;
				}

				span:last-child {
					font-size: 14px;
					color: #282828;
					margin-left: 11px;
				}

			}
		}

		li:last-child {
			margin-right: 0;
		}
	}

	.dataAssetAPI {
		li {
			img {
				display: block;
				margin: 0 auto 20px;
			}
		}
	}

	.dataAssetPledgeFinancing {
		li {
			>img {
				height: 30px;
				display: block;
				margin: 0 auto 20px;
			}

			.logo {
				width: 100px;
				height: 100px;
				border: 1px solid #CACACA;
				background: #fff;
				padding: 0 10px;
				margin: 0 auto 20px;

				img {
					width: 100%;
					height: 100%;
					object-fit: contain;
				}
			}
		}
	}

	.partnerBlue {
		background: url(../../assets/img/home/partner.png) center center no-repeat;
	}

	.partnerRed {
		background: url(../../assets/img/home/partnerRed.jpg) center center no-repeat;
	}

	.partnerGreen {
		background: url(../../assets/img/home/partnerGreen.jpg) center center no-repeat;
	}

	.partnerOrange {
		background: url(../../assets/img/home/partnerOrange.jpg) center center no-repeat;
	}

	.cooperativePartner {
		width: 100%;
		height: 460px;
		background-size: cover !important;
		padding: 54px 0 90px;
		box-sizing: border-box;
		margin-top: 75px;
		margin-bottom: 75px;

		>div {
			width: 1200px;
			margin: 0 auto;

			>p {
				font-size: 28px;
				font-weight: bold;
				color: #fff;
				text-align: center;
				margin-bottom: 50px;
			}

			ul {
				display: flex;
				flex-wrap: wrap;

				.animated {
					animation-duration: 0.5s !important;
					/* 使用!important提高优先级 */
				}

				li {
					width: 210px;
					height: 100px;
					line-height: 100px;
					background: #FFFFFF;
					border-radius: 4px;
					padding: 0 15px;
					box-sizing: border-box;
					margin-right: 37px;
					margin-bottom: 37px;
					text-align: center;

					img {
						max-width: 100%;
						height: 80px;
						object-fit: contain;
						vertical-align: middle;
					}
				}

				li:nth-child(5n) {
					margin-right: 0;
				}
			}
		}
	}
</style>
